<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>


 <v-container fluid>
          <v-row>
             <v-col cols="12" sm="12">
            <v-card style="margin: 8px; height: 100%;">
                <v-card-title class="c-title">
                   Company Attachments
                </v-card-title>
                <v-card-text>
                    <v-row >
                        <v-col cols="12" sm="12">
                            <template>
                                <!-- <v-data-table :headers="headers_company_attachment" :items="companyOfferingAttachmentList" :search="search">
                                     <template v-slot:item.download="{item}">
                                         <a :href="item.fileUrl" target="_blank">Download</a>
                                      </template>     
                                </v-data-table> -->
                                 <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                                    <th class="text-left">File name</th>
                                                     <th class="text-left">Download</th>
                                            </thead>
                                            <tbody>
                                            <tr v-for="item in companyOfferingAttachmentList" :key="item.id">
                                                <td>{{ item.filename }}</td>
                                                <td><a :href="item.fileUrl" target="_blank">Download</a></td>
                                                
                                            </tr>
                                            </tbody>
                                        </template>
                                        </v-simple-table>
                                </template>
                        </v-col>
                    </v-row> 
                </v-card-text>
            </v-card>
             </v-col>
          </v-row>
        </v-container>
        <v-container fluid v-if="instructionlist.length > 0">
            <v-row>
                <v-col cols="12" sm="12">
                    <v-card style="margin: 8px; height: 100%;">
                        <v-card-title class="c-title">
                        Instructions
                        </v-card-title>
                        <v-card-text>
                            <v-row >
                                <v-col cols="12" sm="12">
                                    <template>
                                        <v-simple-table>
                                            <template v-slot:default>
                                                <tbody>
                                                    <tr v-for="item in instructionlist" :key="item.id">
                                                        <td v-html="item.instr"></td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </template>
                                </v-col>
                            </v-row> 
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>



    
        <v-container fluid="">
            <v-row>
                 <v-col cols="12" sm="6">
            <v-card style="margin: 8px; height: 100%;">
                <v-card-title class="c-title">
                    Company Criteria
                </v-card-title>
                <v-card-text>
                    <v-row v-if="showcriteria">
                        <v-col cols="12" sm="12">
                            <template>
                                <v-data-table :headers="headers" :items="criteria" :search="search"></v-data-table>
                            </template>
                        </v-col>
                    </v-row> 
                </v-card-text>
            </v-card>
                 </v-col>
             <v-col cols="12" sm="6">
            <v-card style="margin: 8px; height: 100%;">
                <v-card-title class="c-title">
                    Company Selection Procedure
                </v-card-title>
                <v-card-text>
                    <v-row v-if="showcriteria">
                        <v-col cols="12" sm="12">
                            <template>

                                <v-data-table :headers="headers_sel_proc" :items="selction_procedure" :search="search">
                                     <template v-slot:item.isfinal="{ item }">
                                            <span v-if="item.isfinal">
                                            <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                                            </span>
                                            <span v-else>
                                            --
                                            </span>
                                        </template>                                   
                                </v-data-table>
                            </template>
                        </v-col>
                    </v-row> 
                </v-card-text>
            </v-card>
             </v-col>
            </v-row>
        </v-container>

        <v-container>
          <v-row>
             <v-col cols="12" sm="12">
            <v-card style="margin: 8px; height: 100%;">
                <v-card-title class="c-title">
                   Questions answered by You while applying
                </v-card-title>
                <v-card-text v-if="questions">
                    <v-row >
                        <v-col cols="12" sm="12">
                            <template>
                     <v-simple-table>
                      <thead>
                        <tr>
                          <th class="text-left">Questions</th>
                          <th class="text-left">Answer</th>
                          <th class="text-left">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="it in questions">
                          <td>{{it.question}}</td>
                          <td v-if="it.answerfile && !it.answerDescrp"><a v-bind:href="it.answerfile" target="blank" class="alink">{{it.answer}}</a>
                          </td>
                          <td v-else-if="it.answerDescrp">{{it.answerDescrp}}<br><span style="font-weight:bold" >File: </span><a v-bind:href="it.answerfile" target="blank" class="alink">{{it.answer}}</a>
                          </td>
                          <td v-else>{{it.answer}}
                          </td>
                          <td> <v-avatar  style size="25" class="edit-avatar">
                                <v-icon class="edit-v-icon mr-2" @click="editAnswers(it)">edit</v-icon>
                               </v-avatar>
                          </td>
                        </tr>
                      </tbody>
                   <!--------------- edit dialog start-->

                        <v-dialog v-model="questiondialog" persistent max-width="900px" lazy absolute>
                                <v-card style="padding:30px">
                                <!-- {{finalarray}} -->
                                <strong >Ouestion :</strong> {{finalarray.question}}<br>
                                <row v-if="finalarray.ismultiplechoise != true && finalarray.ischoiceaplicable != true &&finalarray.is_attachment_with_description != true  && finalarray.is_attachmentrequired != true  ">
                                        <v-text-field  v-model="finalarray.answer"
                                            clearable
                                            required>
                                        </v-text-field>
                                        </br>
                                </row>
                                
                          <v-row v-else-if="finalarray.ismultiplechoise == true " max-width="555">
                            <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                            <v-row>
                                <v-col cols="3" sm="3" v-for="(role, index) in finalarray.choicelist" :key="index">
                                    <v-checkbox                                                          
                                      :label="role.choice"
                                      color="success"
                                      v-model="role.isselected"
                                      ></v-checkbox>                               
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                           <v-row v-else-if="finalarray.ischoiceaplicable == true " max-width="555">
                            <v-col cols="10" sm="10" class="mx-auto" style="margin-top:-30px">
                            <br>
                            <span style="font-weight:bold;"> Selected previously :</span> {{finalarray.fanswer[0].fanswer}}
                            <v-row>
                                <v-col cols="10" sm="9">
                                     <v-radio-group row v-model="finalarray.singleanswer">
                                        <v-radio  
                                          v-for="n in finalarray.choicelist"
                                          :key="n"
                                          :label="` ${n.choice}`"
                                          :value="n"
                                        ></v-radio>
                                        
                                      </v-radio-group>                              
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>

                          <v-row v-else-if="finalarray.is_attachmentrequired == true "> 
                          <v-row>
                            <v-col cols="5" sm="5" style="padding:30px">
                                    <span style="font-weight:bold" >Existing File: </span><a v-bind:href="finalarray.answerfile_Url" target="blank" class="alink">{{finalarray.answerfile}}</a>
                            </v-col>
                                <v-col cols="6" sm="6" >
                                     <v-file-input type="file" style="margin-top:30px;"  placeholder="Select Attachment if you want to edit the existing" prepend-icon="mdi-file" ref="file" show-size required="true"
                                    clearable v-model="finalarray.file"
                                    ></v-file-input>  
                                </v-col>
                          </v-row>
                          </v-row>
                          <v-row v-else-if="finalarray.is_attachment_with_description == true " max-width="555">
                            
                                <v-col cols="10" sm="10">
                                    <v-text-field  v-model="finalarray.answer"
                                            clearable
                                            required>
                                        </v-text-field>
                                        </br>
                                 </v-col>
                                <v-col cols="4" sm="4" >
                                        <span style="font-weight:bold" >Existing File: </span><a v-bind:href="finalarray.answerfile_Url" target="blank" class="alink">{{finalarray.answerfile}}</a>
                                </v-col>
                                <v-col cols="6" sm="6">
                                        <v-file-input type="file" placeholder="Select Attachment if you want to edit the existing" prepend-icon="mdi-file" ref="file" show-size required="true"
                                    clearable v-model="finalarray.file"
                                    ></v-file-input>  
                                </v-col>
                             
                          </v-row>
                                
                                 <v-card-actions>
                                    <v-row class="pr-5" justify="end">
                                    <v-btn @click="questiondialog=false" text>cancel</v-btn>
                                    <v-btn @click="editanswerbystudent(finalarray)" dark color="blue">Edit</v-btn>
                                    </v-row>
                                </v-card-actions>
                                </v-card>
                            </v-dialog>


















                    <!--------------- edit dialog end-->
                </v-simple-table>
                               
                            </template>
                        </v-col>
                    </v-row> 
                </v-card-text>
                <v-card-text v-else>
                     <span><center> Not applied yet</center></span>
                </v-card-text>
            </v-card>
             </v-col>
          </v-row>
        </v-container>
 <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
              </v-overlay>
        <v-container fluid>
            <v-card >
                <v-card-title class="c-title">Company Information And Criteria</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Company</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{company_name}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Company Type</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{companytype}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Schedule Date</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{schedule_date}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Industry Type</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{industrytype}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Min Package</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{minpackage}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Max Package</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{maxpackage}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Job Locations</span>
                        </v-col>
                        <v-col cols="12" md="2">
                         :<span v-for="(item, index) in locations" :key="index">
                             {{item}}</span>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Min Stipend</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{minstipend}}
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Max Stipend</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{maxstipend}}
                        </v-col>
                        <!-- <v-col cols="12" sm="2">
                            <span class="lbl">Job Description</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{job_description}}
                        </v-col> -->
                        <v-col cols="12" sm="2">
                            <span class="lbl">Internship Type</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{internship_type}}
                        </v-col>

                        <v-col cols="12" sm="2">
                            <span class="lbl">Dead Backlog </span>
                        </v-col>
                         <v-col cols="12" md="2">
                            :
                            <span v-if="is_dead_backlog_allowed">Allowed</span>
                           
                            <span v-else style="color:#E53935">Not Allowed
                            </span>
                        </v-col>
                       
                        <v-col cols="12" sm="2">
                            <span class="lbl">Live Backlog </span>
                        </v-col>
                        <v-col cols="12" md="2">
                            :
                            <span v-if="backlog" >Allowed</span>
                        
                            <span v-else style="color:#E53935">Not Allowed</span>
                            
                        </v-col>
                        
                         <v-col cols="12" sm="2">
                            <span class="lbl">Specific Criteria</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{specificcriteria}}
                        </v-col>

                        <v-col cols="12" sm="2">
                            <span class="lbl">Placed student</span>
                        </v-col>
                        <v-col cols="12" md="2">:
                            <span v-if="isplacedstudentallowed == true">Allowed</span>
                           
                            <span v-else style="color:#E53935">Not Allowed
                            </span>
                        </v-col>

                         <v-col cols="12" sm="2">
                            <span class="lbl">Intern student</span>
                        </v-col>
                        <v-col cols="12" md="2">:
                            <span v-if="isinternstudentallowed == true">Allowed</span>
                           
                            <span v-else style="color:#E53935">Not Allowed
                            </span>
                        </v-col>



                        <v-col cols="12" sm="2">
                            <span class="lbl">Offering</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{placementtype}}
                        </v-col>

                         <v-col cols="12" sm="2">
                            <span class="lbl">Year Down </span>
                        </v-col>
                         <v-col cols="12" md="2">
                            :
                            <span v-if="isyeardownallowed">Allowed</span>
                           
                            <span v-else style="color:#E53935">Not Allowed
                            </span>
                        </v-col>
                        <v-col cols="12" sm="2">
                            <span class="lbl">Package Description</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{description}}
                        </v-col>
                         
                    </v-row>
                    
                    <v-row>
                    <v-col cols="12" sm="2">
                            <span class="lbl">Stipend Description</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{stipend_description}}
                        </v-col>
                    <v-col cols="12" sm="2">
                            <span class="lbl">Bond Description</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{bond_description}}
                        </v-col>
                    <v-col cols="12" md="2">
                            <span class="lbl">Semester:</span>
                        </v-col>
                        <v-col cols="12" md="2">
                            : {{semester}}
                        </v-col>
                    <v-col cols="12" md="12">
                        <hr style="height:2px;border-width:0;color:gray;background-color:gray">
                    </v-col> 
                     <v-col cols="12" md="6">
                     <!-- <v-data-table :headers="headersprogram" :items="programlist" class="elevation-1" :search="search">
                           </v-data-table> -->
                                             <!--------------- edited by KIRAN-->  
                    
                    <v-row>

                        <v-card style="margin: 8px; height: 100%;">
                            <v-card-title class="c-title">
                                Eligible Branches  
                            </v-card-title>
                        </v-card>                            


                            </v-row>

                            <v-data-table :headers="headersprogram" :items="programlist" class="elevation-1" :search="search">
                            </v-data-table>  
                   </v-col>
                    </v-row>
                   
                    
                       
                    
                </v-card-text>
            </v-card>
    </v-container>














    </div>
</template>
<script>
    import axios from "axios";
    export default {
        data: () => ({
            search: "",
            selected_company: null,
            companyOfferingAttachmentList:[],
            items: "",
            nameidmap: "",
            snackbar: "",
            snackbar_msg: "",
            color: "",
            branches: "",
            schedule_date: "",
            questiondialog:false,
            companytype: "",
            industrytype: "",
            placementtype: "",
            maxstipend:"",
            minstipend:"",
            organization: "",
            programlist:[],
            degree: "",
            selected_degree: null,
            semester:"",
            internship_type:"",
            percentage: "",
            description:"",
            criteria: "",
            selction_procedure:'',
            minpackage: "",
            maxpackage: "",
            locations: "",
            job_description: "",
            stipend_description:"",
            bond_description:"",
            specificcriteria: "",
            is_live_backlog_allowed: false,
            is_dead_backlog_allowed: false,
            isplacedstudentallowed:false,
            isinternstudentallowed:false,
            isyeardownallowed:false,
            questions:[],
            overlay:false,
            instructionlist:[],
            finalarray:[],
            company_name: "",
            headers: [
                { text: "Degree", value: "degree", sortable: false },
                { text: "Criteria(percentage/cpi)", value: "percentage", sortable: false },
                { text: "Program", value: "program", sortable: false },
            ],
            headers_sel_proc: [
                { text: "Round No", value: "round_number", sortable: false },
                { text: "Round Details", value: "companyround", sortable: false },
                { text: "Date", value: "date", sortable: false },
                { text: "Isfinal", value: "isfinal", sortable: false },
            ],
            headers_company_attachment:[
                  { text: "Filename", value: "filename", sortable: false },
                  { text:"Download File",value:"download", sortable: false}
            ],
             headersprogram: [
               {
                    text: 'Organization',
                    value: 'org',
                    sortable: true
                },
                {
                   text: 'Program',align: 'left',sortable: true,value: 'program',
                 },
                 {
                   text: 'Year',align: 'left',sortable: true,value: 'year',
                 },
                
            ],
            company_id: "",
            showcriteria: false,
            editedItem: {
                degree: "",
                percentage: "",
                id: "",
                round_number:'',
                isfinal:'',
                companyround:'',
            },
            dialog: false,
            backlog: false,
            specificcriteria: null,
        }),

        methods: {
            editcriteria(item) {
                this.editedIndex = this.criteria.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },

            getofferinginfo() {
                if (this.$store.getters.company_id == null) {
                    // alert('comapny id not passed')
                    this.$router.push({ name: "coe-live-dashbord" });
                }
                //this.divoff=this.$store.getters.divoff_id
                const data = {
                    offering: this.$store.getters.company_id,
                };
                axios
                    .post("/TPOCompanyScheduling/CompanyofferingInfo", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.branches = res.data.branches;
                            this.schedule_date = res.data.schedule_date;
                            this.companytype = res.data.companytype;
                            this.industrytype = res.data.industrytype;
                            this.placementtype = res.data.placementtype;
                            this.organization = res.data.organization;
                            this.semester = res.data.semester;
                            this.internship_type= res.data.internship_type;
                            this.degree = res.data.degree;
                            this.criteria = res.data.criteria;
                            this.selction_procedure = res.data.selction_procedure;
                            this.specificcriteria = res.data.specificcriteria;
                            this.backlog = res.data.backlog;
                            this.is_dead_backlog_allowed = res.data.is_dead_backlog_allowed;
                             this.isinternstudentallowed = res.data.isinternstudentallowed;
                              this.isplacedstudentallowed = res.data.isplacedstudentallowed;
                              this.isyeardownallowed = res.data.isyeardownallowed;
                            this.showcriteria = true;
                            this.minpackage = res.data.minpackage;
                            this.maxpackage = res.data.maxpackage;
                            this.maxstipend = res.data.maxstipend;
                            this.minstipend = res.data.minstipend;
                            this.locations = res.data.locations;
                            this.job_description = res.data.job_description;
                            this.programlist=res.data.programlist;
                            this.company_name = res.data.company_name;
                            this.questions = res.data.questions;
                            this.description = res.data.description;
                            this.stipend_description = res.data.stipend_description;
                            this.bond_description = res.data.bond_description;
                            this.instructionlist = res.data.instructionlist;
                            console.log(res.data.instructionlist)
                        } else {
                            //error
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    })
                    .finally(() => {
                        //close loader
                        // this.get_ay_std_ft_loader = false;
                    });
                axios
                    .post("/TPOCompanyScheduling/getCompanyOfferingForFileAttachment",data)
                    .then((res) => {
                        if(res.data.msg=="200")
                        {
                           // window.alert(`List of Attachments: ${JSON.stringify(res.data.companyOfferingAttachmentList)}`)
                            this.companyOfferingAttachmentList=res.data.companyOfferingAttachmentList
                        }
                        else
                        {
                            window.alert("Some Problem Occurred")
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    })
            },
            editAnswers(item){
                const data = {
                    question: item,
                };
                axios
                    .post("/LearnerData/editquestionanswerbystudent", data)
                    .then((res) => {
                    
                        if (res.data.msg == "200") {
                            this.finalarray = res.data.final;
                            this.questiondialog=true;
                        } 
                        else if (res.data.code == "failed") {
                            
                            this.showSnackbar("#4caf50", res.data.message);
                        }
                        else {
                            //error
                            this.showSnackbar("#b71c1c", "Could Not Delete Company offering" );
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    })
                    this.close();
            },
            editanswerbystudent(item){
                this.overlay=true;
             let formData = new FormData();
          formData.append("singleanswer", JSON.stringify(item.singleanswer));
          formData.append("file", item.file);
          formData.append("answer", item.answer);
          formData.append("choicelist", JSON.stringify(item.choicelist));
          formData.append("question", item.question);
          formData.append("ischoiceaplicable", item.ischoiceaplicable);
          formData.append("ismultiplechoise", item.ismultiplechoise);
          formData.append("is_attachmentrequired", item.is_attachmentrequired);
          formData.append("is_attachment_with_description", item.is_attachment_with_description);
          formData.append("offeringquestionquestion_id", item.offeringquestionquestion_id);
          formData.append("offering_id", item.offering_id);
       
                // const data = {
                //     finalarray: item,
                // };
                axios
                    .post("/LearnerData/editanswerbystudent", formData)
                    .then((res) => {
                    
                        if (res.data.code == "200") {
                          //  this.finalarray = res.data.final;
                            this.questiondialog=false;
                            this.overlay=false;
                            this.getofferinginfo();
                        } 
                        else if (res.data.code == "failed") {
                            
                            this.showSnackbar("#4caf50", res.data.message);
                        }else if (res.data.code == "NA") {
                            
                            this.showSnackbar("#4caf50", res.data.msg);
                        }
                        else {
                            //error
                            this.showSnackbar("#b71c1c", "Could Not Delete Company offering" );
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    })
                    this.close();
            },
            
        },
        mounted() {
            // this.get_company_list();
            this.getofferinginfo();
        },
    };
</script>
<style scoped>
    .ec-title {
        background-image: linear-gradient(-100deg, #f4faba, #d64907);
        color: #fff;
    }
    .c-title {
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #fff;
    }
    .tc-title {
        background-image: linear-gradient(-90deg, #fff, #057996);
        color: #fff;
        border-radius: 3px;
    }
    .btn-title {
        background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
        color: #fff;
    }
    .card-title {
        background-image: linear-gradient(-90deg, #8edffd, #034c70);
        color: #fff;
    }
    .lbl {
        color: #1705e6;
    }
    .c-title {
        background-image: linear-gradient(-90deg, #befcf7, #03948d);
        color: #fff;
    }
</style>
>
